import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import kul1 from "../images/kul1.jpg";
import meeting from "../images/meeting.webp";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from 'axios';

import { Button, Portal, Typography } from "@mui/material";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

const ProjectGalerie = () => {
  let config = {
    method: "get",
    url: "http://localhost:8080/arts",
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-API-KEY': "123"
     
    },
  };

  const [open, setOpen] = React.useState(false);
  const [projectName, setProjectName] = useState("");
  const [error, setError] = useState(false);
  const [listOfProjects, setListOfProjects] = useState([]);
  useEffect(() => {
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setListOfProjects(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  });
  const submitAddProject = () => {};

  const handleClose = () => {
    setOpen(false);
  };

  const validateInput = (e) => {
    if (e.target.value.length == 0) {
      setError(true);
    } else {
      setError(false);
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Neues Projekt anlegen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ein Projekt ist sozusagen eine Kategorie . Es sollte ein Überschrift
            für mehrere Meetings bzw. Sessions geben
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Name"
            fullWidth
            variant="standard"
            error={error}
            onChange={(e) => {
              setProjectName(e.target.value);
              validateInput(e);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button
            onClick={() => {
              submitAddProject();
            }}
          >
            Erstellen
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3}>
      {listOfProjects.map((project) => {
  return (
    <Grid size={4}>
      <Item
        key={project.id}
        elevation={5}
        className="min-h-44"
        style={{
          backgroundImage: `url(${kul1})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></Item>
      <Item className="flex flex-row items-center">
        <h4>1</h4>
        <h4 className="text-3xl mx-auto">{project.name}</h4>
        <Button>Öffnen</Button>
      </Item>
    </Grid>
  );
})}


        <Grid size={4} className=" hover:bg-opacity-55 cursor-pointer">
          <Item key={1} elevation={1} className=" min-h-44 hover:bg-opacity-55">
            <div className="flex flex-1 justify-center items-center mt-5 ">
              <svg
                fill="#999999"
                width="135px"
                height="135px"
                version="1.1"
                viewBox="144 144 512 512"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#999999"
                stroke-width="0.00512"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke="#CCCCCC"
                  stroke-width="1.024"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g>
                    {" "}
                    <path d="m525.05 548.56c-9.9023 8.3438-20.613 15.676-31.969 21.895-4.9375 2.6953-8.0625 7.8242-8.1992 13.449-0.13281 5.625 2.7422 10.895 7.5469 13.82 4.8047 2.9297 10.805 3.0742 15.746 0.37891 13.207-7.2383 25.66-15.777 37.176-25.484 3.3203-2.6523 5.4258-6.5352 5.8398-10.762 0.41406-4.2266-0.89844-8.4453-3.6367-11.691-2.7383-3.2461-6.6758-5.25-10.914-5.5508-4.2344-0.30078-8.4141 1.1211-11.59 3.9453z"></path>{" "}
                    <path d="m605.32 306.23c-6.2539-13.688-13.855-26.715-22.691-38.898-3.3125-4.5469-8.7969-6.9844-14.391-6.3906-5.5977 0.59375-10.449 4.125-12.734 9.2656-2.2812 5.1445-1.6484 11.113 1.6641 15.66 7.6211 10.477 14.184 21.688 19.582 33.461 1.2305 2.7617 3.2344 5.1094 5.7656 6.7578 2.5312 1.6523 5.4883 2.5352 8.5117 2.543 2.2617 0.011719 4.4961-0.47656 6.5469-1.4258 3.8164-1.7461 6.7812-4.9414 8.2344-8.875 1.4531-3.9375 1.2773-8.2891-0.48828-12.098z"></path>{" "}
                    <path d="m496.69 196.04c-5.082-2.4141-11.062-1.9336-15.695 1.2578-4.6328 3.1914-7.2109 8.6133-6.7617 14.223 0.44922 5.6055 3.8594 10.551 8.9375 12.965 11.703 5.5586 22.824 12.273 33.191 20.047 3.3398 2.5039 7.5391 3.582 11.672 2.9922 4.1328-0.59375 7.8633-2.8008 10.367-6.1406 2.5078-3.3398 3.582-7.5391 2.9922-11.672-0.58984-4.1367-2.8008-7.8633-6.1406-10.371-12.043-9.0352-24.961-16.84-38.562-23.301z"></path>{" "}
                    <path d="m601.38 461.5c-3.8906-1.5234-8.2305-1.4375-12.055 0.24609-3.8242 1.6797-6.8242 4.8203-8.3281 8.7188-4.6992 12.059-10.598 23.613-17.613 34.488-2.2617 3.5117-3.0391 7.7773-2.1562 11.859 0.88281 4.082 3.3477 7.6484 6.8594 9.9102 3.5117 2.2656 7.7773 3.0391 11.859 2.1602 4.082-0.88281 7.6445-3.3516 9.9102-6.8633 8.1562-12.664 15.02-26.117 20.488-40.156 1.5078-3.8906 1.4102-8.2188-0.26953-12.035-1.6797-3.8164-4.8086-6.8125-8.6953-8.3281z"></path>{" "}
                    <path d="m432.09 176.6c-10.633-1.5039-21.355-2.2617-32.094-2.2695-42.141 0.039063-83.43 11.875-119.19 34.172-35.758 22.297-64.562 54.156-83.145 91.98-18.586 37.82-26.207 80.094-22.004 122.02 4.2031 41.93 20.066 81.848 45.785 115.23 25.723 33.383 60.277 58.895 99.75 73.648 39.473 14.754 82.289 18.156 123.6 9.8281 5.5156-1.1211 10.012-5.1055 11.797-10.445 1.7812-5.3398 0.58203-11.227-3.1484-15.438-3.7344-4.2148-9.4297-6.1211-14.949-4.9961-35.57 7.2227-72.453 4.332-106.47-8.3438-34.012-12.68-63.789-34.637-85.953-63.379-22.168-28.742-35.832-63.125-39.449-99.242-3.6172-36.117 2.9609-72.527 18.988-105.09 16.027-32.566 40.859-59.992 71.684-79.16 30.824-19.172 66.402-29.316 102.7-29.293 9.25-0.003906 18.488 0.64844 27.645 1.9531 0.74609 0.10937 1.4961 0.16406 2.2461 0.16797 5.375 0.019532 10.391-2.7031 13.301-7.2266 2.9102-4.5195 3.3086-10.215 1.0625-15.098-2.25-4.8828-6.832-8.2852-12.16-9.0156z"></path>{" "}
                    <path d="m606.2 357.47c-4.1406 0.53516-7.8984 2.6875-10.453 5.9883-2.5547 3.3047-3.6953 7.4844-3.1719 11.625 1.0703 8.2617 1.6016 16.586 1.5977 24.918 0 4.6797-0.16797 9.3008-0.48438 13.898v-0.003907c-0.29297 4.1797 1.0938 8.3047 3.8516 11.457 2.7578 3.1523 6.6641 5.0742 10.844 5.3359h1.1328c3.9883-0.003906 7.8281-1.5273 10.738-4.2539 2.9102-2.7266 4.6758-6.457 4.9453-10.438 0.37891-5.3125 0.56641-10.664 0.56641-16.102 0-9.6641-0.60938-19.32-1.8281-28.906-0.59375-4.1289-2.7852-7.8594-6.1055-10.387-3.3164-2.5312-7.4922-3.6562-11.633-3.1328z"></path>{" "}
                    <path d="m415.74 316.03c0-5.625-3-10.824-7.8711-13.637s-10.875-2.8125-15.746 0-7.8711 8.0117-7.8711 13.637v68.223h-68.223c-5.625 0-10.824 3-13.637 7.8711s-2.8125 10.875 0 15.746 8.0117 7.8711 13.637 7.8711h68.223v68.223c0 5.625 3 10.824 7.8711 13.637s10.875 2.8125 15.746 0 7.8711-8.0117 7.8711-13.637v-68.223h68.223c5.625 0 10.824-3 13.637-7.8711s2.8125-10.875 0-15.746-8.0117-7.8711-13.637-7.8711h-68.223z"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
            </div>
          </Item>
          <Item className="flex flex-row items-center">
            <div className="mx-auto">
              <Button
                variant="contained"
                color="success"
                onClick={() => setOpen(true)}
              >
                Erstellen
              </Button>
            </div>
          </Item>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProjectGalerie;
