import React from "react";
import ProjectGalerie from "../components/ProjectGalerie";


const Dashboard = () => {
  return (
    <React.Fragment>
      
      <ProjectGalerie/>
    </React.Fragment>
  );
};

export default Dashboard;
